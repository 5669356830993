export const EDUCATION = [
  {
    school: "San José State University",
    degree: "Master of Science - MS, Software Engineering, Data Science Specialization",
    date: "Aug. 2021 – May 2023",
    description: [
      "3.92/4.00 GPA",
      "Worked concurrently through the duration",
      "Courses: Machine Learning, Deep Learning, Data Mining, Advanced Data Mining, Artificial Intelligence and Data Engineering, Cloud Technologies, Software Systems Engineering, Enterprise Software Platforms",
    ],
    categories: ["Full-Stack", "Mobile", "Machine Learning"],
    languages: ["Python", "Java", "JavaScript", "HTML", "CSS"],
    frameworksLibraries: ["React", "Bootstrap", "React Native", "Flask"],
    tools: [
      "Git",
      "Object-Oriented Programming",
      "REST API",
      "Linux",
      "Docker",
      "NoSQL",
      "MongoDB",
      "AWS",
    ],
    machineLearning: ["PyTorch", "TensorFlow", "Keras", "scikit-learn", "NumPy", "pandas"],
  },
  {
    school: "San José State University",
    degree: "Bachelor of Science - BS, Computer Science",
    date: "Aug. 2019 – May 2021",
    description: [
      "3.91/4.00 GPA - Summa Cum Laude, President's Scholar",
      "Outstanding Graduating Senior academic scholarship",
      "Worked concurrently through the last semester",
      "Courses: Introduction to Artificial Intelligence, Data Structures and Algorithms, Operating Systems, Object-Oriented Design, Introduction to Database Management Systems, NoSQL Database Systems, Server-side Web Programming, Programming Paradigms, Computer Architecture, Software Engineering, Formal Languages and Computability, Introduction to Game Studies, Applied Probability and Statistics",
    ],
    categories: ["Full-Stack", "Game"],
    languages: ["Python", "Java", "JavaScript", "HTML", "CSS", "SQL", "PHP"],
    frameworksLibraries: ["React", "Bootstrap", "Django", "Redux"],
    tools: [
      "Git",
      "Object-Oriented Programming",
      "REST API",
      "Linux",
      "PostgreSQL",
      "MySQL",
      "NoSQL",
      "MongoDB",
      "GCP",
      "Unity",
    ],
    machineLearning: [],
  },
  {
    school: "De Anza College",
    degree: "Associate in Science for Transfer - AS-T, Computer Science",
    date: "Sep. 2017 – Jun. 2019",
    description: [
      "3.85/4.00 GPA",
      "Courses: Data Abstraction and Structures, Introduction to x86 Processor Assembly and Computer Architecture, Multivariable Calculus, Differential Equations, Linear Algebra, Physics - Mechanics/Electricity/Magnetism/Fluids/Waves/Optics/Thermodynamics",
    ],
    categories: [],
    languages: ["C++"],
    frameworksLibraries: [],
    tools: ["Git", "Object-Oriented Programming"],
    machineLearning: [],
  },
  {
    school: "Piedmont Hills High School",
    degree: "High School Diploma",
    date: "Aug. 2013 – May 2017",
    description: [
      "4.25/4.00 GPA - Valedictorian",
      "Varsity tennis captain 2015-2017 - 2x MVP, led team of 25 teammates in practices/matches",
      "Taekwondo 3rd degree black belt - instructed classes of 20 students",
    ],
    categories: [],
    languages: ["Java"],
    frameworksLibraries: [],
    tools: ["Git", "Object-Oriented Programming"],
    machineLearning: [],
  },
];
