import chillClickerImg from "../images/chill_clicker.jpg";
import kaggleFeedbackImg from "../images/kaggle_feedback.png";
import kagglePatentImg from "../images/kaggle_patent.png";
import skinDetectorImg from "../images/skin_detector.jpg";
import officeSupplyDepotImg from "../images/office_supply_depot.jpg";
import bjjPredictorImg from "../images/bjj_predictor.jpg";
import connect4aiImg from "../images/connect4_ai.jpg";
import digitRecognizerImg from "../images/digit_recognizer.jpg";
import donutDashImg from "../images/donut_dash.jpg";
import cryptoOnlineImg from "../images/crypto_online.jpg";
import polynomialRegressionImg from "../images/polynomial_regression.jpg";
import infiniteMonkeyImg from "../images/infinite_monkey.jpg";
import iconImg from "../images/icon.jpg";

const ProjectData = [
  {
    image: chillClickerImg,
    title: "Chill Clicker",
    date: "Jan. 2024 – Apr. 2024",
    description: [
      "Developed a simple, relaxing clicker game",
      "Integrated with third-party services like Google Play Games Services and AdMob",
      "Published to Google Play Store",
    ],
    categories: ["Game"],
    languages: ["GDScript"],
    frameworksLibraries: [],
    tools: ["Git", "Godot"],
    machineLearning: [],
    source: "",
    demo: "https://play.google.com/store/apps/details?id=com.ryanlongtran.chillclicker",
  },
  {
    image: kaggleFeedbackImg,
    title: "Feedback Prize - Predicting Effective Arguments (Kaggle Competition)",
    date: "May. 2022 – Aug. 2022",
    description: [
      "Placed 31st out of 1,557 competitors (top 2%)",
      "Tasked to predict the effectiveness of argumentative essays from U.S. students in grades 6-12",
      "Trained BERT models and ensembled using XGBoost, LightGBM, and CatBoost",
    ],
    categories: ["Machine Learning", "Competition"],
    languages: ["Python"],
    frameworksLibraries: [],
    tools: ["Git"],
    machineLearning: ["PyTorch", "scikit-learn", "Transformers", "NumPy", "pandas", "Matplotlib"],
    source: "",
    demo: "https://www.kaggle.com/certification/competitions/ryantran2165/feedback-prize-effectiveness",
  },
  {
    image: kagglePatentImg,
    title: "U.S. Patent Phrase to Phrase Matching (Kaggle Competition)",
    date: "Mar. 2022 – Jun. 2022",
    description: [
      "Placed 60th out of 1,889 competitors (top 3.2%)",
      "Tasked to predict the semantic similarity between phrases for U.S. patents",
      "Trained BERT models and ensembled using XGBoost, LightGBM, and CatBoost",
    ],
    categories: ["Machine Learning", "Competition"],
    languages: ["Python"],
    frameworksLibraries: [],
    tools: ["Git"],
    machineLearning: ["PyTorch", "scikit-learn", "Transformers", "NumPy", "pandas", "Matplotlib"],
    source: "",
    demo: "https://www.kaggle.com/certification/competitions/ryantran2165/us-patent-phrase-to-phrase-matching",
  },
  {
    image: skinDetectorImg,
    title: "Skin Detector (DEFUNCT)",
    date: "Sep. 2021 – Dec. 2021",
    description: [
      "Developed an Android mobile app with React Native that detects skin conditions, such as melanoma and carcinoma",
      "Trained convolutional neural networks with TensorFlow and deployed on-device models with TensorFlow.js",
    ],
    categories: ["Mobile", "Machine Learning"],
    languages: ["Python", "JavaScript"],
    frameworksLibraries: ["React Native"],
    tools: ["Git"],
    machineLearning: ["TensorFlow", "Keras", "scikit-learn", "NumPy", "pandas"],
    source: "https://github.com/sjsucmpe272-fall21/SkinDetector",
    demo: "",
  },
  {
    image: officeSupplyDepotImg,
    title: "Office Supply Depot (DEFUNCT)",
    date: "Oct. 2020 – Dec. 2020",
    description: [
      "Led a team to develop a full-stack e-commerce website with a React frontend and Django REST API backend",
      "Utilized GCP Maps and Directions APIs to optimize package delivery routes for the hypothetical business",
    ],
    categories: ["Full-Stack"],
    languages: ["Python", "JavaScript", "HTML", "CSS"],
    frameworksLibraries: ["React", "Bootstrap", "Django", "Redux"],
    tools: ["Git", "REST API", "PostgreSQL", "GCP"],
    machineLearning: [],
    source: "https://github.com/ryantran2165/office-supply-depot-frontend",
    demo: "",
  },
  {
    image: bjjPredictorImg,
    title: "BJJ Predictor (DEFUNCT)",
    date: "Jun. 2020 – Aug. 2020",
    description: [
      "Built full-stack web app with React frontend and Flask REST API backend that predicts the winner of Brazilian Jiu-Jitsu matches with 70% accuracy using deep neural networks",
      "Performed full machine learning workflow: web scraping with Scrapy, data exploration with Seaborn, data cleaning with pandas, model training with TensorFlow, and deployment with AWS DynamoDB and Heroku",
    ],
    categories: ["Full-Stack", "Machine Learning"],
    languages: ["Python", "JavaScript", "HTML", "CSS"],
    frameworksLibraries: ["React", "Bootstrap", "Flask"],
    tools: ["Git", "REST API", "Linux", "AWS", "NoSQL"],
    machineLearning: ["TensorFlow", "Keras", "scikit-learn", "NumPy", "pandas"],
    source: "https://github.com/ryantran2165/bjj-predictor",
    demo: "",
  },
  {
    image: connect4aiImg,
    title: "Connect 4 AI",
    date: "Aug. 2020 – Dec. 2020",
    description: [
      "Implemented Connect 4 AI web app with varying difficulties using deep Q-learning and minimax",
      "Trained deep Q-network using self-play reinforcement learning with limited computational resources",
    ],
    categories: ["Machine Learning"],
    languages: ["JavaScript", "HTML", "CSS"],
    frameworksLibraries: ["React", "Bootstrap"],
    tools: ["Git", "GCP"],
    machineLearning: ["TensorFlow"],
    source: "https://github.com/ryantran2165/connect4-ai",
    demo: "https://www.ryanlongtran.com/connect4-ai",
  },
  {
    image: digitRecognizerImg,
    title: "Digit Recognizer",
    date: "Jan. 2020 – Aug. 2020",
    description: [
      "Created web app that recognizes user-drawn digits with 90% accuracy using self-implemented feedforward (FFNN) and convolutional (CNN) neural networks",
      "Solidified understanding of fundamental machine learning theory and mathematics behind FFNNs and CNNs",
    ],
    categories: ["Machine Learning"],
    languages: ["JavaScript", "HTML", "CSS"],
    frameworksLibraries: ["React", "Bootstrap"],
    tools: ["Git"],
    machineLearning: [],
    source: "https://github.com/ryantran2165/digit-recognizer",
    demo: "https://www.ryanlongtran.com/digit-recognizer",
  },
  {
    image: donutDashImg,
    title: "Donut Dash",
    date: "Mar. 2020 – May 2020",
    description: ["Collaborated with an artist to publish a 2D Unity platformer game"],
    categories: ["Game"],
    languages: ["C#"],
    frameworksLibraries: [],
    tools: ["Git", "Object-Oriented Programming", "Unity"],
    machineLearning: [],
    source: "https://github.com/ryantran2165/donut-dash",
    demo: "https://ryantran2165.itch.io/donut-dash",
  },
  {
    image: cryptoOnlineImg,
    title: "Crypto Online (DEFUNCT)",
    date: "Jan. 2020",
    description: [
      "Constructed full-stack cryptography web app supporting 3 ciphers and secure user login/registration using sessions",
    ],
    categories: ["Full-Stack"],
    languages: ["JavaScript", "HTML", "CSS", "SQL", "PHP"],
    frameworksLibraries: ["Bootstrap"],
    tools: ["Git", "Linux", "MySQL"],
    machineLearning: [],
    source: "https://github.com/ryantran2165/crypto-online",
    demo: "",
  },
  {
    image: polynomialRegressionImg,
    title: "Polynomial Regression",
    date: "May 2020",
    description: [
      "Created web app that performs and graphs polynomial regression using gradient descent on user-inputted data points",
    ],
    categories: ["Machine Learning"],
    languages: ["JavaScript", "HTML", "CSS"],
    frameworksLibraries: ["React", "Bootstrap"],
    tools: ["Git"],
    machineLearning: [],
    source: "https://github.com/ryantran2165/polynomial-regression",
    demo: "https://www.ryanlongtran.com/polynomial-regression",
  },
  {
    image: infiniteMonkeyImg,
    title: "Infinite Monkey",
    date: "Dec. 2019",
    description: [
      "Created web app that uses a genetic algorithm to evolve a target phrase, simulating the infinite monkey theorem",
    ],
    categories: ["Machine Learning"],
    languages: ["JavaScript", "HTML", "CSS"],
    frameworksLibraries: ["React", "Bootstrap"],
    tools: ["Git"],
    machineLearning: [],
    source: "https://github.com/ryantran2165/infinite-monkey",
    demo: "https://www.ryanlongtran.com/infinite-monkey",
  },
  {
    image: iconImg,
    title: "Personal Website",
    date: "Oct. 2019 – Present",
    description: ["Created website with React as a portfolio for my projects and career"],
    categories: [],
    languages: ["JavaScript", "HTML", "CSS"],
    frameworksLibraries: ["React", "Bootstrap"],
    tools: ["Git"],
    machineLearning: [],
    source: "https://github.com/ryantran2165/ryantran2165.github.io/tree/source",
    demo: "",
  },
];

export default ProjectData;
