export const EXPERIENCES = [
  {
    title: "Software Developer",
    company: "EPRI",
    location: "Palo Alto, CA",
    date: "Jun. 2023 – Present",
    description: [
      [
        "Spearheaded 3 web apps using C#, Blazor, REST APIs, SQL Server, and Azure that:",
        [
          "Improved the management of software licenses",
          "Streamlined the comparison of energy sources",
          "Enhanced the effectiveness of data communication",
        ],
      ],
      [
        "Developed 2 desktop apps using C#, WPF, and Azure that:",
        [
          "Refined the workflow of data labeling",
          "Bolstered the success of a computer-based training program",
        ],
      ],
    ],
    categories: ["Full-Stack", "Desktop"],
    languages: ["Python", "C#", "JavaScript", "HTML", "CSS", "SQL"],
    frameworksLibraries: ["ASP.NET", "Blazor", "Bootstrap", "WPF", "Entity Framework"],
    tools: [
      "Git",
      "Object-Oriented Programming",
      "REST API",
      "Linux",
      "Docker",
      "SQL Server",
      "SQLite",
      "Azure",
      "Agile",
    ],
    machineLearning: [],
  },
  {
    title: "Associate Software Developer",
    company: "EPRI",
    location: "Palo Alto, CA",
    date: "Jul. 2022 – Jun. 2023",
    description: [
      [
        "Built an authentication and authorization framework using C#, Blazor, REST APIs, SQL Server, and Azure that:",
        ["Unified the access control and development process of apps and APIs within the company"],
      ],
      [
        "Implemented 2 desktop apps using C#, C++, and WPF that:",
        [
          "Boosted the efficiency of nuclear fuel inspections",
          "Simplified the configuration of wiki sites",
        ],
      ],
    ],
    categories: ["Full-Stack", "Desktop"],
    languages: ["Python", "C#", "JavaScript", "HTML", "CSS", "SQL", "C++"],
    frameworksLibraries: ["ASP.NET", "Blazor", "Bootstrap", "WPF", "Entity Framework"],
    tools: [
      "Git",
      "Object-Oriented Programming",
      "REST API",
      "Linux",
      "Docker",
      "SQL Server",
      "Azure",
      "Agile",
    ],
    machineLearning: [],
  },
  {
    title: "Student Software Developer",
    company: "EPRI",
    location: "Palo Alto, CA",
    date: "Feb. 2021 – Jul. 2022",
    description: [
      [
        "Created 2 web apps using C#, Python, JavaScript, Angular, REST APIs, SQL Server, and Azure that:",
        [
          "Automated the quality assurance testing of a reactor simulation program",
          "Raised the accuracy of reactor containment inspections",
        ],
      ],
    ],
    categories: ["Full-Stack"],
    languages: ["Python", "C#", "JavaScript", "HTML", "CSS", "SQL"],
    frameworksLibraries: ["ASP.NET", "Blazor", "Bootstrap", "Angular", "WPF", "Entity Framework"],
    tools: [
      "Git",
      "Object-Oriented Programming",
      "REST API",
      "Linux",
      "Docker",
      "SQL Server",
      "SQLite",
      "Azure",
      "Agile",
    ],
    machineLearning: [],
  },
  {
    title: "Front-End Developer",
    company: "MediRev Solutions",
    location: "San José, CA",
    date: "Mar. 2018 – Jul. 2018",
    description: [
      ["Designed company website and logo using HTML, CSS, WordPress, and Adobe Illustrator", []],
    ],
    categories: [],
    languages: ["HTML", "CSS"],
    frameworksLibraries: [],
    tools: [],
    machineLearning: [],
  },
  {
    title: "Developer Connection Intern",
    company: "Samsung Electronics America",
    location: "Mountain View, CA",
    date: "Jul. 2017 – Oct. 2017",
    description: [
      [
        "Independently learned C#, Unity, and Unity Virtual Reality to develop a Unity VR arcade game as a demo",
        [],
      ],
      ["Fixed bugs in a Samsung Unity VR workshop/tutorial", []],
    ],
    categories: [],
    languages: ["C#"],
    frameworksLibraries: [],
    tools: ["Unity"],
    machineLearning: [],
  },
];
